import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./theme"
import { CssBaseline } from "@mui/material"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Root from "./routes/Root"
import About from "./routes/About"
import Products from "./routes/Products"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
)

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/about",
        element: <About />,
      },
      {
        index: true,
        element: <Products />,
      },
    ],
  },
])

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
