import React from "react"
import { Box, Card, Typography } from "@mui/material"

function About() {
  return (
    <>
      <Typography variant="h2">About Us</Typography>
      <Typography sx={{ my: 2 }}>We use machine learning to create next generation solutions for fitness!</Typography>
      <Typography sx={{ my: 2 }}>
        Since 2020 we have worked on using wearable sensor data to recognize exercises and give athletes information
        that could previously only be painstakingly found by manually looking through video recordings.
      </Typography>
      <Box display="flex">
        <Card sx={{ mr: 2, p: 2 }}>
          <b>Contact</b>
          <Typography>
            <a href="mailto:hello@glasswing.dev">hello@glasswing.dev</a>
          </Typography>
        </Card>
        <Card sx={{ mr: 2, p: 2 }}>
          <Typography>
            <b>Glasswing ApS</b> <br />
            CVR 41295236
            <br />
            Georginevej 1 <br />
            2970 Hørsholm, DK
          </Typography>
        </Card>
      </Box>
    </>
  )
}

export default About
