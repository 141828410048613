import React from "react"
import { AppBar, Box, Button, Container, Toolbar, Typography } from "@mui/material"
import { Outlet, Link as RouterLink } from "react-router-dom"

function Root() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6"
                      component="a"
                      href="/"
                      sx={{
                        flex: 1,
                        color: "inherit",
                      }}
          >
            Glasswing.dev
          </Typography>
          <Button color="inherit" to="/" component={RouterLink}>
            Products
          </Button>
          <Button color="inherit" to="/about" component={RouterLink}>
            About
          </Button>
          <Box sx={{ flex: 1 }}></Box>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 4 }}>
        <Outlet />
      </Container>
      <Box sx={{
        position: "fixed",
        width: "100%",
        bottom: 0,
      }}>
        <Box sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: "flex",
          mb: 2,
        }}>
          Copyright &#169; 2022 Glasswing ApS
        </Box>
      </Box>
    </Box>
  )
}

export default Root
