import React from "react"
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import atlonIcon from "../assets/atlon_icon.png"

function Products() {
  const theme = useTheme()
  return (
    <>
      <Typography variant="h2" sx={{ mb: 4 }}>
        Products
      </Typography>
      <Box display="inline-block">
        <Card raised={true} sx={{maxWidth: theme.spacing(60)}}>
          <CardActionArea href="https://atlon.app" target="_blank">
            <Box sx={{ display: "flex" }}>
              <CardMedia
                component="img"
                image={atlonIcon}
                sx={{
                  width: 150,
                }}
              />
              <CardContent>
                <Typography variant="h5" sx={{mb: 2}}>
                  Atlon
                </Typography>
                <Typography variant="body2">
                  Functional fitness tracking powered by machine learning on your Apple Watch
                </Typography>
              </CardContent>
            </Box>
          </CardActionArea>
          <CardActions>
            <Button href="https://apps.apple.com/app/apple-store/id1522683306?pt=121754852&ct=glasswing.dev&mt=8"
                    target="_blank">
              App Store
            </Button>
          </CardActions>
        </Card>
      </Box>
    </>
  )
}

export default Products
